/*!
 * froala_editor v2.7.3 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2017 Froala Labs
 */

(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['jquery'], factory);
    } else if (typeof module === 'object' && module.exports) {
        // Node/CommonJS
        module.exports = function( root, jQuery ) {
            if ( jQuery === undefined ) {
                // require('jQuery') returns a factory that requires window to
                // build a jQuery instance, we normalize how we use modules
                // that require this pattern but the window provided is a noop
                // if it's defined (how jquery works)
                if ( typeof window !== 'undefined' ) {
                    jQuery = require('jquery');
                }
                else {
                    jQuery = require('jquery')(root);
                }
            }
            return factory(jQuery);
        };
    } else {
        // Browser globals
        factory(window.jQuery);
    }
}(function ($) {
/**
 * Italian
 */

$.FE.LANGUAGE['it'] = {
  translation: {
    // Place holder
    "Type something": "Digita qualcosa",

    // Basic formatting
    "Bold": "Grassetto",
    "Italic": "Corsivo",
    "Underline": "Sottolineato",
    "Strikethrough": "Barrato",

    // Main buttons
    "Insert": "Inserisci",
    "Delete": "Cancella",
    "Cancel": "Cancella",
    "OK": "Ok",
    "Back": "Indietro",
    "Remove": "Rimuovi",
    "More": "Di pi\u00f9",
    "Update": "Aggiornare",
    "Style": "Stile",

    // Font
    "Font Family": "Carattere",
    "Font Size": "Dimensione Carattere",

    // Colors
    "Colors": "Colori",
    "Background": "Sfondo",
    "Text": "Testo",
    "HEX Color": "Colore esagono",

    // Paragraphs
    "Paragraph Format": "Formattazione",
    "Normal": "Normale",
    "Code": "Codice",
    "Heading 1": "Intestazione 1",
    "Heading 2": "Intestazione 2",
    "Heading 3": "Intestazione 3",
    "Heading 4": "Intestazione 4",

    // Style
    "Paragraph Style": "Stile di paragrafo",
    "Inline Style": "Stile in fila",

    // Alignment
    "Align": "Allinea",
    "Align Left": "Allinea a Sinistra",
    "Align Center": "Allinea al Cento",
    "Align Right": "Allinea a Destra",
    "Align Justify": "Giustifica",
    "None": "Nessuno",

    // Lists
    "Ordered List": "Elenchi ordinato",
    "Unordered List": "Elenchi non ordinata",

    // Indent
    "Decrease Indent": "Riduci Rientro",
    "Increase Indent": "Aumenta Rientro",

    // Links
    "Insert Link": "Inserisci Link",
    "Open in new tab": "Apri in nuova scheda",
    "Open Link": "Link aperto",
    "Edit Link": "Modifica link",
    "Unlink": "Rimuovi link",
    "Choose Link": "Scegli link",

    // Images
    "Insert Image": "Inserisci immagine",
    "Upload Image": "Carica immagine",
    "By URL": "Per URL",
    "Browse": "Sfogliare",
    "Drop image": "Rilascia Immagine",
    "or click": "oppure fai click su",
    "Manage Images": "Gestione immagini",
    "Loading": "Caricamento",
    "Deleting": "Eliminazione",
    "Tags": "Etichetta",
    "Are you sure? Image will be deleted.": "Sei sicuro? Immagine verr\u00e0 cancellata.",
    "Replace": "Sostituisci",
    "Uploading": "Caricamento",
    "Loading image": "Caricamento immagine",
    "Display": "Visualizzazione",
    "Inline": "In fila",
    "Break Text": "Testo di separazione",
    "Alternate Text": "Testo alternativo",
    "Change Size": "Cambio formato",
    "Width": "Larghezza",
    "Height": "Altezza",
    "Something went wrong. Please try again.": "Qualcosa \u00e8 andato storto. Riprova, per favore.",
    "Image Caption": "Didascalia",
    "Advanced Edit": "Avanzato",

    // Video
    "Insert Video": "Inserisci Video",
    "Embedded Code": "Codice incorporato",
    "Paste in a video URL": "Incolla l'URL di un video",
    "Drop video": "Rilascia video",
    "Your browser does not support HTML5 video.": "Il tuo browser non supporta il video html5.",
    "Upload Video": "Carica video",

    // Tables
    "Insert Table": "Inserisci Tabella",
    "Table Header": "Intestazione della tabella",
    "Remove Table": "Rimuovere tavolo",
    "Table Style": "Stile di tabella",
    "Horizontal Align": "Allineamento orizzontale",
    "Row": "Riga",
    "Insert row above": "Inserisci una Riga Prima",
    "Insert row below": "Inserisci una Riga Dopo",
    "Delete row": "Cancella Riga",
    "Column": "Colonna",
    "Insert column before": "Inserisci una Colonna Prima",
    "Insert column after": "Inserisci una Colonna Dopo",
    "Delete column": "Cancella Colonna",
    "Cell": "Cella",
    "Merge cells": "Unisci Cella",
    "Horizontal split": "Divisione Orizzontale",
    "Vertical split": "Divisione Verticale",
    "Cell Background": "Sfondo della cella",
    "Vertical Align": "Allineamento verticale",
    "Top": "Alto",
    "Middle": "Mezzo",
    "Bottom": "Fondo",
    "Align Top": "Allinea in alto",
    "Align Middle": "Allinea al centro",
    "Align Bottom": "Allinea in basso",
    "Cell Style": "Stile di cella",

    // Files
    "Upload File": "Carica File",
    "Drop file": "Rilascia File",

    // Emoticons
    "Emoticons": "Emoticon",
    "Grinning face": "Sorridendo volto",
    "Grinning face with smiling eyes": "Sorridendo faccia con gli occhi sorridenti",
    "Face with tears of joy": "Faccia con lacrime di gioia",
    "Smiling face with open mouth": "Volto sorridente con la bocca aperta",
    "Smiling face with open mouth and smiling eyes": "Volto sorridente con la bocca aperta e gli occhi sorridenti",
    "Smiling face with open mouth and cold sweat": "Volto sorridente con la bocca aperta e sudore freddo",
    "Smiling face with open mouth and tightly-closed eyes": "Volto sorridente con la bocca aperta e gli occhi ben chiusi",
    "Smiling face with halo": "Volto sorridente con alone",
    "Smiling face with horns": "Volto sorridente con le corna",
    "Winking face": "Fare l'occhiolino volto",
    "Smiling face with smiling eyes": "Volto sorridente con gli occhi sorridenti",
    "Face savoring delicious food": "Volto assaporando cibo delizioso",
    "Relieved face": "Volto Sollevata",
    "Smiling face with heart-shaped eyes": "Volto sorridente con gli occhi a forma di cuore",
    "Smiling face with sunglasses": "Volto sorridente con gli occhiali da sole",
    "Smirking face": "Compiaciuto affrontare",
    "Neutral face": "Volto Neutral",
    "Expressionless face": "Volto inespressivo",
    "Unamused face": "Faccia non divertito",
    "Face with cold sweat": "Faccia con sudore freddo",
    "Pensive face": "Volto pensieroso",
    "Confused face": "Volto confuso",
    "Confounded face": "Volto confusi",
    "Kissing face": "Baciare faccia",
    "Face throwing a kiss": "Volto lanciando un bacio",
    "Kissing face with smiling eyes": "Baciare faccia con gli occhi sorridenti",
    "Kissing face with closed eyes": "Baciare faccia con gli occhi chiusi",
    "Face with stuck out tongue": "Faccia con la lingua fuori bloccato",
    "Face with stuck out tongue and winking eye": "Faccia con la lingua fuori bloccato e ammiccante occhio",
    "Face with stuck out tongue and tightly-closed eyes": "Faccia con la lingua fuori bloccato e gli occhi ben chiusi",
    "Disappointed face": "Volto deluso",
    "Worried face": "Faccia preoccupata",
    "Angry face": "Faccia arrabbiata",
    "Pouting face": "Fare il muso volto",
    "Crying face": "Piangere volto",
    "Persevering face": "Perseverando volto",
    "Face with look of triumph": "Affrontare con sguardo di trionfo",
    "Disappointed but relieved face": "Siamo rimasti delusi ma sollevato la faccia",
    "Frowning face with open mouth": "Accigliandosi volto con la bocca aperta",
    "Anguished face": "Volto Angosciato",
    "Fearful face": "Volto Temendo",
    "Weary face": "Volto stanco",
    "Sleepy face": "Faccia assonnata",
    "Tired face": "Volto stanco",
    "Grimacing face": "Smorfie volto",
    "Loudly crying face": "Ad alta voce che grida volto",
    "Face with open mouth": "Volto con la bocca aperta",
    "Hushed face": "Faccia ovattata",
    "Face with open mouth and cold sweat": "Volto con la bocca aperta e sudore freddo",
    "Face screaming in fear": "Volto urlando nella paura",
    "Astonished face": "Volto Stupito",
    "Flushed face": "Viso rosso",
    "Sleeping face": "Viso addormentato",
    "Dizzy face": "Faccia vertigini",
    "Face without mouth": "Volto senza bocca",
    "Face with medical mask": "Volto con la mascherina medica",

    // Line breaker
    "Break": "Separazione",

    // Math
    "Subscript": "Pedice",
    "Superscript": "Apice",

    // Full screen
    "Fullscreen": "Schermo intero",

    // Horizontal line
    "Insert Horizontal Line": "Inserisci Divisore Orizzontale",

    // Clear formatting
    "Clear Formatting": "Cancella formattazione",

    // Undo, redo
    "Undo": "Annulla",
    "Redo": "Ripeti",

    // Select all
    "Select All": "Seleziona Tutto",

    // Code view
    "Code View": "Visualizzazione di codice",

    // Quote
    "Quote": "Citazione",
    "Increase": "Aumentare",
    "Decrease": "Diminuzione",

    // Quick Insert
    "Quick Insert": "Inserto rapida",

    // Spcial Characters
    "Special Characters": "Personaggi speciali",
    "Latin": "Latino",
    "Greek": "Greco",
    "Cyrillic": "Cirillico",
    "Punctuation": "Punteggiatura",
    "Currency": "Moneta",
    "Arrows": "Frecce",
    "Math": "Matematica",
    "Misc": "Misc",

    // Print.
    "Print": "Stampare",

    // Spell Checker.
    "Spell Checker": "Correttore ortografico",

    // Help
    "Help": "Aiuto",
    "Shortcuts": "Scorciatoie",
    "Inline Editor": "Editor in linea",
    "Show the editor": "Mostrare l'editor",
    "Common actions": "Azioni comuni",
    "Copy": "Copia",
    "Cut": "Tagliare",
    "Paste": "Incolla",
    "Basic Formatting": "Formattazione di base",
    "Increase quote level": "Aumenta il livello delle quote",
    "Decrease quote level": "Diminuire il livello delle quote",
    "Image / Video": "Immagine / video",
    "Resize larger": "Ridimensionare più grande",
    "Resize smaller": "Ridimensionare più piccolo",
    "Table": "Tavolo",
    "Select table cell": "Selezionare la cella della tabella",
    "Extend selection one cell": "Estendere la selezione di una cella",
    "Extend selection one row": "Estendere la selezione una riga",
    "Navigation": "Navigazione",
    "Focus popup / toolbar": "Messa a fuoco popup / barra degli strumenti",
    "Return focus to previous position": "Tornare alla posizione precedente",

    // Embed.ly
    "Embed URL": "Incorpora url",
    "Paste in a URL to embed": "Incolla in un url da incorporare",

    // Word Paste.
    "The pasted content is coming from a Microsoft Word document. Do you want to keep the format or clean it up?": "Il contenuto incollato proviene da un documento di Word Microsoft. vuoi mantenere il formato o pulirlo?",
    "Keep": "Mantieni",
    "Clean": "Pulito",
    "Word Paste Detected": "È stata rilevata la colla di parole"
  },
  direction: "ltr"
};

}));
